import { Badge } from "@mantine/core";
import Translate from "Components/Translate";
import { CREATED_AT, STATUS, TESTER, UPDATED_AT } from "Constant";
import { formatDate } from "helper/dateFormat";
import { getPurchaseRequests } from "helper/getStatusColor";
import { z } from "zod";

export const activeObj = {
  name: "active",
  title: "Active",
  inputForm: "SelectInput",
  options: [
    {
      value: "",
      label: "all",
      name: "active",
    },
    {
      value: "true",
      label: "active",
      name: "active",
    },
    {
      value: "false",
      label: "not active",
      name: "active",
    },
  ],
};

export const civilityObj = {
  name: "civility",
  title: "Civility",
  inputForm: "SelectInput",
  schema: z.any({
    required_error: "Required",
    invalid_type_error: "Required",
  }),
  options: [
    {
      value: "M",
      label: "Mr",
      name: "civility",
    },
    {
      value: "MME",
      label: "Mrs",
      name: "civility",
    },
  ],
};

export const testerObj = {
  name: "tester",
  title: TESTER,
  inputForm: "SelectInput",
  schema: z.boolean({
    required_error: "Required",
    invalid_type_error: "Required",
  }),

  options: [
    {
      value: true,
      label: "Testeur",
      name: "tester",
    },
    {
      value: false,
      label: "Client",
      name: "tester",
    },
  ],
};

export const captureObj = {
  title: "Capture capture",
  name: "clientCapture",
  inputForm: "SelectInput",
  options: [
    {
      value: "FB",
      label: "Facebook",
      name: "capture",
    },
    {
      value: "Instagram",
      label: "Instagram",
      name: "capture",
    },
    {
      value: "Tiktok",
      label: "Tiktok",
      name: "capture",
    },
    {
      value: "Email",
      label: "Email",
      name: "capture",
    },
    {
      value: "Events",
      label: "Events",
      name: "capture",
    },
    {
      value: "Mouth to ear",
      label: "Mouth to ear",
      name: "capture",
    },
    {
      value: "Others",
      label: "Others",
      name: "capture",
    },
  ],
};

export const saleOrTry = {
  name: "type",
  title: "Type",
  inputForm: "SelectInput",
  options: [
    {
      value: "sale",
      label: "Sale",
    },
    {
      value: "try",
      label: "Try",
    },
  ],
};

export const createdAt = {
  accessorFn: (originalRow) => originalRow.created_at,
  id: "created_at",
  header: CREATED_AT,
  Cell: ({ row }) => {
    const { created_at } = row.original;
    return formatDate(created_at);
  },
};

export const updatedAt = {
  accessorFn: (originalRow) => originalRow.updated_at,
  id: "updated_at",
  header: UPDATED_AT,
  Cell: ({ row }) => {
    const { updated_at } = row.original;
    return formatDate(updated_at);
  },
};

export const statusObj = {
  accessorFn: (originalRow) => originalRow.status,
  id: "status",
  header: STATUS,

  filterFn: "equals",
  filterVariant: "select",
  mantineFilterSelectProps: {
    data: [
      { value: "canceled", label: "Annulé" },
      { value: "draft", label: "Brouillon" },
      { value: "confirmed", label: "Confirmé" },
      { value: "paid", label: "Payé" },
      { value: "created", label: "Crée" },
      { value: "ready", label: "Prêt" },
    ],
  },

  Cell: ({ row }) => {
    const { status } = row.original;
    return (
      <Badge color={getPurchaseRequests(status)} size="lg">
        {status}
      </Badge>
    );
  },
};
