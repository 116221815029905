import MonitoringPage from "pages/MonitoringPage";
import TaximeterAlertPage from "pages/TaximeterAlertPage";
import TaximeterProblemsPage from "pages/TaximeterProblemsPage";
import TaxisPage from "pages/TaxisPage";

const EXPLOITATION_ROUTES = [
  {
    path: "/taxis",
    element: <TaxisPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/taximter-problems",
    element: <TaximeterProblemsPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/taximter-alert",
    element: <TaximeterAlertPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/monitoring",
    element: <MonitoringPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
];

export default EXPLOITATION_ROUTES;
