import ClientPages from "pages/ClientPages";
import SheetOfClientPage from "pages/SheetOfClientPage";
import SheetOfTaxiPage from "pages/SheetOfTaxiPage";

import BillPage from "pages/BillPage";
import ReadBillPage from "pages/ReadBillPage";
import CreateBillPage from "pages/CreateBillPage";

import CollaboratorPage from "pages/CollaboratorPage";
import ReadCollaborator from "pages/ReadCollaborator";

import EstimatePage from "pages/EstimatePage";
import CreateEstimatePage from "pages/CreateEstimatePage";
import ReadEstimatePage from "pages/ReadEstimatePage/index.test";

import DeliveryFormPage from "pages/DeliveryFormPage";
import ReadDeliveryFormPage from "pages/ReadDeliveryFormPage";

import DistributorPage from "pages/DistributorPage";
import ReadDistributorPage from "pages/ReadDistributorPage";

import LeadPage from "pages/LeadPage";
import ReadLeadsPage from "pages/ReadLeadsPage";
import PurchaseOrderPage from "pages/PurchaseOrderPage";

import ReadService from "pages/ReadService";
import ServicesListPage from "pages/ServicesListPage";

import PricingServicePage from "pages/PricingPages/PricingServicePage";
import PricingProductPage from "pages/PricingPages/PricingProductPage";

import EditEstimate from "pages/CreateEstimatePage/EditEstimate";
import PricingPages from "pages/PricingPages";
import ReadPurchaseOrderPage from "pages/ReadPurchaseOrderPage";

import SchedulesPage from "pages/SchedulesPage";
import PaymentNotification from "pages/PaymentNotification";

// import ReadPricingServicePage from "pages/ReadPricing/Pricings";
// import ReadPricingProductPage from "pages/ReadPricing/Products";

const COMMERCIAL_ROUTES = [
  {
    path: "/client",
    element: <ClientPages />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/client/:code",
    element: <SheetOfClientPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/taxi/:code",
    element: <SheetOfTaxiPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  {
    path: "/collaborator",
    element: <CollaboratorPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/collaborator/:code",
    element: <ReadCollaborator />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  {
    path: "/distributor",
    element: <DistributorPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/distributor/:code",
    element: <ReadDistributorPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  {
    path: "/purchase-order",
    element: <PurchaseOrderPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/purchase-order/:code",
    element: <ReadPurchaseOrderPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  // delivary form
  {
    path: "/delivery-form",
    element: <DeliveryFormPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/delivery-form/:code",
    element: <ReadDeliveryFormPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  // estimate
  {
    path: "/estimate",
    element: <EstimatePage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/estimate/:code",
    element: <ReadEstimatePage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/create-estimate",
    element: <CreateEstimatePage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/edit-estimate/:code",
    element: <EditEstimate />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  // bill
  {
    path: "/bill",
    element: <BillPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/bill/:code",
    element: <ReadBillPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/create-bill/:code",
    element: <CreateBillPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  // leads
  {
    path: "/leads",
    element: <LeadPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/leads/:code",
    element: <ReadLeadsPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  // services
  {
    path: "/services-list",
    element: <ServicesListPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/services-list/:code",
    element: <ReadService />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  // pricing
  {
    path: "/pricing-services",
    element: <PricingServicePage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  // {
  //   path: "/pricing-services/:code",
  //   element: <ReadPricingServicePage />,
  //   roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  // },
  {
    path: "/pricing-product",
    element: <PricingProductPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  // {
  //   path: "/pricing-product/:code",
  //   element: <ReadPricingProductPage />,
  //   roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  // },

  {
    path: "/pricing",
    element: <PricingPages />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  {
    path: "/schedules",
    element: <SchedulesPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  {
    path: "/payment-notification",
    element: <PaymentNotification />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
];

export default COMMERCIAL_ROUTES;
