export const DEFAULT_SORTING = [{ id: "created_at", desc: true }];
export const DEFAULT_PAGINATION = { pageIndex: 0, pageSize: 10 };
export const DEFAULT_COLUMN_FILTERS = [{ id: "$locale", desc: "fr" }];
export const DEFAULT_COLUMN_PINNING = {
  left: ["actions"],
  right: ["status"],
};

export const PAGINATION_CHOICES = () => [
  { value: "5", label: "5" },
  { value: "10", label: "10" },
  { value: "15", label: "15" },
  { value: "20", label: "20" },
  { value: "25", label: "25" },
  { value: "30", label: "30" },
  { value: "50", label: "50" },
];

export const PAGINATION_CHOICES_FN = (total) => {
  // Create the final option object with total
  const finalOption = { value: String(total), label: `Total ${String(total)}` };

  const baseOptions = [
    { value: "5", label: "5" },
    { value: "10", label: "10" },
    { value: "15", label: "15" },
    { value: "20", label: "20" },
    { value: "25", label: "25" },
    { value: "30", label: "30" },
    { value: "50", label: "50" },
  ];

  const isTotalIncluded = baseOptions.some(
    (option) => option.value === finalOption.value
  );

  return isTotalIncluded ? baseOptions : [...baseOptions, finalOption];
};
