import Translate from "Components/Translate";
import { GO_TO_TAXI_SHEET } from "Constant";
import { Button } from "primereact/button";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Text, Badge, Paper } from "@mantine/core";
import { getColor, statusCheckbox } from "./data";

const PopUpMarker = ({ data }) => {
  const [state, setState] = useState();
  const dispatch = useDispatch();

  const {
    active,
    brand,
    carNumber,
    code,
    createdAt,
    destination,
    imei,
    location,
    model,
    ownerCode,
    places,
    serialNumber,
    socketId,
    status,
    updatedAt,
  } = data;

  const path = `/taxi/${code}`;

  const handleOnClick = () => {
    window.open(path, "_blank");
  };

  return (
    <Fragment>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}

      <Paper
        radius="md"
        withBorder
        p="lg"
        style={{
          borderColor: getColor(status),
          borderWidth: "4px",
        }}
      >
        <Badge color={getColor(status)} size="lg">
          {statusCheckbox(status)}
        </Badge>
        <Text ta="center" c="dimmed" fz="sm">
          <Text ta="center" fz="lg" fw={500} mt="md">
            {brand} | {model}
          </Text>

          <Text ta="center" fz="lg" fw={500} mt="md">
            {carNumber}
          </Text>
        </Text>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px",
          }}
        >
          <Button
            type="submit"
            size="small"
            label={<Translate>{GO_TO_TAXI_SHEET}</Translate>}
            onClick={handleOnClick}
            style={{ color: "white" }}
          />
        </div>
      </Paper>
    </Fragment>
  );
};

export default PopUpMarker;
